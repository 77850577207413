import React from 'react';
import Lottie from 'lottie-react';
import spinAnimation from './spinAnimation.json';
import blocksAnimation from './blocksAnimation.json';
import wavyAnimation from './wavyAnimation.json';

interface SpinnerProps {
  theme: string;
  animationName: 'spin' | 'blocks' | 'wavy';
  text?: string;
}

/**
 * The spinner uses the Lottie animations provided by insights
 * and includes optionally configured text below for different
 * spinners.
 *
 * @returns Spinner JSX
 */
const Spinner = ({ theme, animationName, text = '' }: SpinnerProps) => {
  const getAnimation = () => {
    switch (animationName) {
      case 'spin':
        return spinAnimation;
      case 'blocks':
        return blocksAnimation;
      case 'wavy':
        return wavyAnimation;
      default:
        return spinAnimation;
    }
  };

  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 250,
          maxWidth: 250,
        }}
      >
        <Lottie animationData={getAnimation()} loop />
      </div>
      {text ? (
        <p className={`loaderText loaderText_${theme}`}>{text}</p>
      ) : null}
    </div>
  );
}

export default Spinner;
