/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useEffect } from 'react';
import i18n from 'i18next';
import { app } from '../services/ms-teams-service';
import { getSignedInUser, getUserAccess } from '../services/learnersService';
import { SignedInUser, User, UserType } from '../global/types';

export type ContextType = {
  user?: UserType | undefined;
  updateUser?: () => void;
  themeState: string;
  canAccess: boolean;
};

interface ContextProps {
  children: React.ReactNode;
}

export const UserContext = React.createContext<ContextType>({
  user: undefined,
  updateUser: () => undefined,
  themeState: 'default',
  canAccess: false,
});

const UserContextProvider = (props: ContextProps) => {
  const [user, setUserData] = useState<UserType>();
  const [themeState, setThemeState] = useState<string>('default');
  const [canAccess, setCanAccess] = useState(false);

  useEffect(() => {
    app.registerOnThemeChangeHandler((theme: string) => {
      // Update the themeState with the new theme.
      setThemeState(theme);
    });
  }, []);

  const updateUser = async () => {
    const teamsAppData = await app.getContext();

    setThemeState(teamsAppData.app?.theme || 'default');

    let tenantId = '';
    if (teamsAppData.user?.tenant?.id) tenantId = teamsAppData.user?.tenant?.id;

    const canAccessResult = await getUserAccess(tenantId);

    setCanAccess(canAccessResult.data);

    // check canAccess via an endpoint on API else return so rest fo code does not run
    if (canAccessResult.data) {
      let response: any;
      try {
        response = await getSignedInUser(tenantId);
      } catch (e) {
        response = e;
      }

      const languageCode = teamsAppData.app.locale;

      // By default the App loads the en-GB translations
      if (languageCode !== 'en-GB') {
        i18n.changeLanguage(languageCode);
      }

      const userData: User = {
        id: '',
        organisationName: '',
        displayName: '',
        email: '',
        createdAt: '',
        pronoun: '',
        consciousColourEnergy: [],
        lessConsciousColourEnergy: [],
        consciousColourEnergyValues: {
          fieryRedEnergy: 0,
          coolBlueEnergy: 0,
          sunshineYellowEnergy: 0,
          earthGreenEnergy: 0,
        },
        lessConsciousColourEnergyValues: {
          fieryRedEnergy: 0,
          coolBlueEnergy: 0,
          sunshineYellowEnergy: 0,
          earthGreenEnergy: 0,
        },
        colourEnergiesConfirmedAt: '',
        highlights: [],
        strengths: [],
        weakness: [],
        communicationDos: [],
        communicationDonts: [],
        allowShareColourEnergies: false,
        energyCodeToName: null,
        settings: null,
        userStatementSettings: null,
        theme: teamsAppData.app?.theme || 'default',
        error: null,
        isNominated: false,
        hasNominatePeople: false,
        tenantId: null,
        languageCode: teamsAppData.app.locale,
        fullProfile: true,
        profileViews: 0,
        insightsForThisWeek: [],
        chapters: [],
      };

      if (response && response.status === 200) {
        const responseData = response.data as SignedInUser;
        userData.id = responseData.id;
        userData.organisationName = responseData.organisationName;
        userData.displayName = responseData.displayName;
        userData.consciousColourEnergyValues =
          responseData.consciousColourEnergyValues;
        userData.lessConsciousColourEnergyValues =
          responseData.lessConsciousColourEnergyValues;
        userData.email = responseData.mail;
        userData.createdAt = responseData.createdAt;
        userData.pronoun = responseData.pronoun;
        userData.chapters = responseData.chapters;
        userData.consciousColourEnergy = responseData.consciousColourEnergy;
        userData.lessConsciousColourEnergy =
          responseData.lessConsciousColourEnergy;
        userData.colourEnergiesConfirmedAt =
          responseData.colourEnergiesConfirmedAt;
        userData.allowShareColourEnergies =
          responseData.allowShareColourEnergies;
        userData.energyCodeToName = responseData.energyCodeToName;
        userData.settings = responseData.settings;
        userData.userStatementSettings = responseData.userStatementSettings;
        userData.isNominated = responseData.isNominated;
        userData.hasNominatePeople = responseData.hasNominatePeople;
        userData.profileViews = responseData.profileViews;
        userData.highlights = responseData.highlights || [];
        userData.strengths = responseData.strengths || [];
        userData.weakness = responseData.weakness || [];
        userData.communicationDos = responseData.communicationDos || [];
        userData.communicationDonts = responseData.communicationDonts || [];
        userData.insightsForThisWeek = responseData.insightsForThisWeek;
      } else {
        userData.error = response.response.status || null;
      }

      setUserData(userData);
    }
  };

  const { children } = props;

  const obj = useMemo(
    () => ({
      user,
      updateUser,
      themeState,
      canAccess,
    }),
    [user, updateUser, themeState, canAccess]
  );

  return <UserContext.Provider value={obj}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
